import {elementByQuery, pageReady} from "./modules/utils";
// import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import zenscroll from "zenscroll";
import lazySizes from 'lazysizes';

lazySizes.cfg.lazyClass = 'mypet-lazy';

class LayoutModule {
  static init() {
    /**
     * Гамбургер меню
     */
    const mainContainer = elementByQuery("#mypet-main-container");

    document.querySelector("#mypet-mobile__hamburger-menu").addEventListener("click", function (event) {
      event.preventDefault();

      if (!mainContainer.classList.contains("mypet-sidebar--active")) {
        LayoutModule.openHamburgerMenu();
      } else {
        LayoutModule.closeHamburgerMenu();
      }
    });

    elementByQuery("#mypet-sidebar__active-fade").addEventListener("click", () => {
      LayoutModule.closeHamburgerMenu();
      LayoutModule.closeMobileContacts();
    });

    elementByQuery("#mypet-mobile-header__contacts-toggle").addEventListener("click", () => {
      LayoutModule.toggleMobileContacts();
    });

    LayoutModule.initMobileSearch();
    LayoutModule.initZenScroll();

    /**
     * Меню с животными (Desktop)
     */

    let menuTimeout;
    let menuEnterTimeout;
    let isMenuOpened = false;

    const petMenuItems = document.querySelectorAll(".mypet-main-menu__item");
    const petMenuCategoryItems = document.querySelectorAll(".mypet-main-menu__menu");

    const menuEnterHandler = (menuItem) => {
      petMenuItems.forEach(item => item.classList.remove("mypet-main-menu__item--active"));

      menuItem.classList.add("mypet-main-menu__item--active");

      petMenuCategoryItems.forEach(menuItem => menuItem.classList.remove("mypet-main-menu__menu--visible"));

      isMenuOpened = true;

      const petType = menuItem.getAttribute("data-category");
      if (petType) {
        const menuList = elementByQuery(`.mypet-main-menu__menu--${petType}`);
        if (menuList) {
          menuList.classList.add("mypet-main-menu__menu--visible");
        }
      }
    };

    petMenuItems.forEach((menuItem) => {
      menuItem.addEventListener("mouseenter", () => {
        clearTimeout(menuTimeout);

        if (isMenuOpened) {
          menuEnterHandler(menuItem);
        } else {
          menuEnterTimeout = setTimeout(() => {
            menuEnterHandler(menuItem);
          }, 200);
        }
      });

      menuItem.addEventListener("mouseleave", () => {
        clearTimeout(menuEnterTimeout);

        const petType = menuItem.getAttribute("data-category");
        if (petType) {
          const menuList = elementByQuery(`.mypet-main-menu__menu--${petType}`);
          if (menuList) {
            menuTimeout = setTimeout(() => {
              menuList.classList.remove("mypet-main-menu__menu--visible");
              petMenuItems.forEach(item => item.classList.remove("mypet-main-menu__item--active"));
              isMenuOpened = false;
            }, 10);
          }
        }
      });
    });

    petMenuCategoryItems.forEach((categoriesItem) => {
      categoriesItem.addEventListener("mouseenter", () => {
        clearTimeout(menuTimeout);
        isMenuOpened = true;
      });

      categoriesItem.addEventListener("mouseleave", () => {
        menuTimeout = setTimeout(() => {
          petMenuCategoryItems.forEach(menuItem => menuItem.classList.remove("mypet-main-menu__menu--visible"));
          petMenuItems.forEach(item => item.classList.remove("mypet-main-menu__item--active"));
          isMenuOpened = false;
        }, 10);
      });
    });
  }

  static openHamburgerMenu() {
    const mainContainer = elementByQuery("#mypet-main-container");
    if (mainContainer.classList.contains("mypet-sidebar--active")) {
      return;
    }
    const sideBar = elementByQuery("#mypet-sidebar__wrapper");

    mainContainer.classList.add("mypet-sidebar--active");
    if (sideBar) {
      // disableBodyScroll(sideBar);
    }
  }

  static closeHamburgerMenu() {
    const mainContainer = elementByQuery("#mypet-main-container");
    if (!mainContainer.classList.contains("mypet-sidebar--active")) {
      return;
    }
    const sideBar = elementByQuery("#mypet-sidebar__wrapper");

    mainContainer.classList.remove("mypet-sidebar--active");
    if (sideBar) {
      // enableBodyScroll(sideBar);
    }
  }

  static toggleMobileContacts() {
    const mainContainer = elementByQuery("#mypet-main-container");
    const contactsElement = elementByQuery('#mypet-mobile__header-contacts');
    if (contactsElement) {
      if (contactsElement.classList.contains("mypet-common--visible")) {
        mainContainer.classList.remove('mypet-layout--contacts-visible');
      } else {
        mainContainer.classList.add('mypet-layout--contacts-visible');
      }
      contactsElement.classList.toggle("mypet-common--visible");
    }
  }

  static closeMobileContacts() {
    const mainContainer = elementByQuery("#mypet-main-container");
    const contactsElement = elementByQuery('#mypet-mobile__header-contacts');
    if (contactsElement) {
      contactsElement.classList.remove("mypet-common--visible");
      mainContainer.classList.remove('mypet-layout--contacts-visible');
    }
  }

  static initMobileSearch() {
    elementByQuery("#mypet-mobile-header__search-toggle").addEventListener("click", (event) => {
      event.preventDefault();

      const normalHeaderElement = elementByQuery("#mypet-mobile-header");
      const mobileSearchElement = elementByQuery("#mypet-mobile-search");

      normalHeaderElement.classList.add("mypet-common--hidden");
      mobileSearchElement.style.display = "flex";
    });

    elementByQuery("#mypet-mobile__hide-search").addEventListener("click", (event) => {
      event.preventDefault();

      const normalHeaderElement = elementByQuery("#mypet-mobile-header");
      const mobileSearchElement = elementByQuery("#mypet-mobile-search");

      normalHeaderElement.classList.remove("mypet-common--hidden");
      mobileSearchElement.style.display = "none";
    });
  }

  static initZenScroll() {
    zenscroll.setup(400, 70);

    document.querySelectorAll(".mypet__scroll-to-top").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        zenscroll.toY(0, 400);
      })
    });

    const urlHash = window.location.hash;
    if (urlHash && urlHash.substr(1)) {
      const targetElement = document.getElementById(urlHash.substr(1));
      if (targetElement) {
        zenscroll.to(targetElement);
      }
    }
  }
}

pageReady(() => {
  LayoutModule.init();
});

export default LayoutModule;
